@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?e433d0');
  src:  url('fonts/icomoon.eot?e433d0#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?e433d0') format('truetype'),
    url('fonts/icomoon.woff?e433d0') format('woff'),
    url('fonts/icomoon.svg?e433d0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-work-warning:before {
  content: "\e974";
}
.icon-success:before {
  content: "\e9eb";
}
.icon-info-mark-circle:before {
  content: "\e9ec";
}
.icon-error:before {
  content: "\e9ed";
}
.icon-edit--authorized-person2:before {
  content: "\e9ee";
}
.icon-Mirror-Horizontal1:before {
  content: "\e9ef";
}
.icon-Mirror-Vertical1:before {
  content: "\e9f0";
}
.icon-aligment-text-center:before {
  content: "\e90d";
}
.icon-aligment-text-left:before {
  content: "\e90e";
}
.icon-aligment-text-right:before {
  content: "\e91b";
}
.icon-amazon:before {
  content: "\e91e";
}
.icon-attachment:before {
  content: "\e923";
}
.icon-background-color-text-filled:before {
  content: "\e926";
}
.icon-background-color-text-outlined:before {
  content: "\e937";
}
.icon-bold-text-customized:before {
  content: "\e938";
}
.icon-bold-text-default:before {
  content: "\e93b";
}
.icon-bold-text-outline:before {
  content: "\e93f";
}
.icon-bulletin:before {
  content: "\e942";
}
.icon-character-color-text:before {
  content: "\e945";
}
.icon-check-package-delivery:before {
  content: "\e947";
}
.icon-crosshair:before {
  content: "\e94e";
}
.icon-double-quote:before {
  content: "\e953";
}
.icon-dropdown-down:before {
  content: "\e954";
}
.icon-dropdown-up:before {
  content: "\e956";
}
.icon-edit--authorized-person1:before {
  content: "\e958";
  color: #22daea;
}
.icon-file-page:before {
  content: "\e959";
}
.icon-flag:before {
  content: "\e962";
}
.icon-flash-auto:before {
  content: "\e971";
}
.icon-flash-off:before {
  content: "\e973";
}
.icon-flash:before {
  content: "\e986";
}
.icon-flashlight-off:before {
  content: "\e98d";
}
.icon-flashlight:before {
  content: "\e994";
}
.icon-folder:before {
  content: "\e99b";
}
.icon-gopuff:before {
  content: "\e9ac";
}
.icon-grubhub:before {
  content: "\e9bd";
}
.icon-ic-notification:before {
  content: "\e9bf";
}
.icon-image:before {
  content: "\e9c0";
}
.icon-in-amenity:before {
  content: "\e9c1";
}
.icon-in-package:before {
  content: "\e9c2";
}
.icon-instacart:before {
  content: "\e9c3";
}
.icon-italic-text:before {
  content: "\e9c4";
}
.icon-keyboard:before {
  content: "\e9c5";
}
.icon-light-bulb:before {
  content: "\e9c6";
}
.icon-light-exposure:before {
  content: "\e9c7";
}
.icon-link:before {
  content: "\e9c8";
}
.icon-list-text-dots:before {
  content: "\e9c9";
}
.icon-list-text-number:before {
  content: "\e9ca";
}
.icon-maintenance:before {
  content: "\e9cb";
}
.icon-microchip:before {
  content: "\e9cc";
}
.icon-microphone:before {
  content: "\e9cd";
}
.icon-Mirror-Horizontal:before {
  content: "\e9ce";
  color: #22daea;
}
.icon-Mirror-Vertical:before {
  content: "\e9cf";
  color: #22daea;
}
.icon-more-vertical:before {
  content: "\e9d0";
}
.icon-move-item:before {
  content: "\e9d1";
}
.icon-newspaper:before {
  content: "\e9d2";
}
.icon-notice:before {
  content: "\e9d3";
}
.icon-ongoing-clock:before {
  content: "\e9d4";
}
.icon-overtime-clock:before {
  content: "\e9d5";
}
.icon-package-delivery:before {
  content: "\e9d6";
}
.icon-packages:before {
  content: "\e9d7";
}
.icon-paragraph-text-left:before {
  content: "\e9d8";
}
.icon-paragraph-text-right:before {
  content: "\e9d9";
}
.icon-pdf:before {
  content: "\e9da";
}
.icon-pet:before {
  content: "\e9db";
}
.icon-postmates:before {
  content: "\e9dc";
}
.icon-profile:before {
  content: "\e9dd";
}
.icon-quote-mark:before {
  content: "\e9de";
}
.icon-Rotate:before {
  content: "\e9df";
}
.icon-share:before {
  content: "\e9e0";
}
.icon-shipt:before {
  content: "\e9e1";
}
.icon-smile-emoji-text:before {
  content: "\e9e2";
}
.icon-spreadsheet:before {
  content: "\e9e3";
}
.icon-star:before {
  content: "\e9e4";
}
.icon-stroke-text-filled:before {
  content: "\e9e5";
}
.icon-stroke-text-outline:before {
  content: "\e9e6";
}
.icon-sword:before {
  content: "\e9e7";
}
.icon-table:before {
  content: "\e9e8";
}
.icon-underline-text:before {
  content: "\e9e9";
}
.icon-unlock-pin:before {
  content: "\e9ea";
}
.icon-email:before {
  content: "\e905";
}
.icon-draggable:before {
  content: "\e902";
}
.icon-add-amenity:before {
  content: "\e900";
}
.icon-add-authorized-contractor:before {
  content: "\e901";
}
.icon-add-authorized-person:before {
  content: "\e903";
}
.icon-add-camera:before {
  content: "\e904";
}
.icon-add-clipboard:before {
  content: "\e906";
}
.icon-add-contractor:before {
  content: "\e907";
}
.icon-add-document:before {
  content: "\e908";
}
.icon-add-note:before {
  content: "\e909";
}
.icon-add-package:before {
  content: "\e90a";
}
.icon-add-party:before {
  content: "\e90b";
}
.icon-add-person:before {
  content: "\e90c";
}
.icon-add-pin:before {
  content: "\e90f";
}
.icon-add-reservation:before {
  content: "\e910";
}
.icon-add-vehicle:before {
  content: "\e911";
}
.icon-add-work-order:before {
  content: "\e912";
}
.icon-add:before {
  content: "\e913";
}
.icon-address-book:before {
  content: "\e914";
}
.icon-ambulance:before {
  content: "\e915";
}
.icon-amenity:before {
  content: "\e916";
}
.icon-arrow-left:before {
  content: "\e917";
}
.icon-arrow-right:before {
  content: "\e918";
}
.icon-authorized-contractor:before {
  content: "\e919";
}
.icon-authorized-person:before {
  content: "\e91a";
}
.icon-badge:before {
  content: "\e91c";
}
.icon-barcode:before {
  content: "\e91d";
}
.icon-bug:before {
  content: "\e91f";
}
.icon-calendar:before {
  content: "\e920";
}
.icon-camera:before {
  content: "\e921";
}
.icon-car-in:before {
  content: "\e922";
}
.icon-car-out:before {
  content: "\e924";
}
.icon-car:before {
  content: "\e925";
}
.icon-check-all:before {
  content: "\e927";
}
.icon-check-package:before {
  content: "\e928";
}
.icon-checkmark:before {
  content: "\e929";
}
.icon-clipboard:before {
  content: "\e92a";
}
.icon-clock:before {
  content: "\e92b";
}
.icon-close:before {
  content: "\e92c";
}
.icon-community:before {
  content: "\e92d";
}
.icon-confirm-amenity:before {
  content: "\e92e";
}
.icon-contractor:before {
  content: "\e92f";
}
.icon-dashboard:before {
  content: "\e930";
}
.icon-day:before {
  content: "\e931";
}
.icon-delete-amenity:before {
  content: "\e932";
}
.icon-delete-authorized-contractor:before {
  content: "\e933";
}
.icon-delete-authorized-person:before {
  content: "\e934";
}
.icon-delete-camera:before {
  content: "\e935";
}
.icon-delete-clipboard:before {
  content: "\e936";
}
.icon-delete-contractor:before {
  content: "\e939";
}
.icon-delete-document:before {
  content: "\e93a";
}
.icon-delete-note:before {
  content: "\e93c";
}
.icon-delete-package:before {
  content: "\e93d";
}
.icon-delete-party:before {
  content: "\e93e";
}
.icon-delete-person:before {
  content: "\e940";
}
.icon-delete-pin:before {
  content: "\e941";
}
.icon-delete-reservation:before {
  content: "\e943";
}
.icon-delete-vehicle:before {
  content: "\e944";
}
.icon-delete-work-order:before {
  content: "\e946";
}
.icon-delivery:before {
  content: "\e948";
}
.icon-document:before {
  content: "\e949";
}
.icon-dollar:before {
  content: "\e94a";
}
.icon-doordash:before {
  content: "\e94b";
}
.icon-download:before {
  content: "\e94c";
}
.icon-driver-license:before {
  content: "\e94d";
}
.icon-edit--authorized-person:before {
  content: "\e94f";
}
.icon-edit-amenity:before {
  content: "\e950";
}
.icon-edit-authorized-contractor:before {
  content: "\e951";
}
.icon-edit-camera:before {
  content: "\e952";
}
.icon-edit-clipboard:before {
  content: "\e955";
}
.icon-edit-contractor:before {
  content: "\e957";
}
.icon-edit-document:before {
  content: "\e95a";
}
.icon-edit-note:before {
  content: "\e95b";
}
.icon-edit-package:before {
  content: "\e95c";
}
.icon-edit-party:before {
  content: "\e95d";
}
.icon-edit-person:before {
  content: "\e95e";
}
.icon-edit-pin:before {
  content: "\e95f";
}
.icon-edit-reservation:before {
  content: "\e960";
}
.icon-edit-vehicle:before {
  content: "\e961";
}
.icon-edit-work-order:before {
  content: "\e963";
}
.icon-edit:before {
  content: "\e964";
}
.icon-elevator:before {
  content: "\e965";
}
.icon-external-link:before {
  content: "\e966";
}
.icon-firefighters:before {
  content: "\e967";
}
.icon-gallery:before {
  content: "\e968";
}
.icon-gate-in:before {
  content: "\e969";
}
.icon-gate-out:before {
  content: "\e96a";
}
.icon-gate:before {
  content: "\e96b";
}
.icon-helpdesk:before {
  content: "\e96c";
}
.icon-history:before {
  content: "\e96d";
}
.icon-house:before {
  content: "\e96e";
}
.icon-houses:before {
  content: "\e96f";
}
.icon-id:before {
  content: "\e970";
}
.icon-in:before {
  content: "\e972";
}
.icon-key:before {
  content: "\e975";
}
.icon-list:before {
  content: "\e976";
}
.icon-location:before {
  content: "\e977";
}
.icon-lyft:before {
  content: "\e978";
}
.icon-menu:before {
  content: "\e979";
}
.icon-message:before {
  content: "\e97a";
}
.icon-minus:before {
  content: "\e97b";
}
.icon-money:before {
  content: "\e97c";
}
.icon-more:before {
  content: "\e97d";
}
.icon-movie:before {
  content: "\e97e";
}
.icon-negate:before {
  content: "\e97f";
}
.icon-note:before {
  content: "\e980";
}
.icon-notification:before {
  content: "\e981";
}
.icon-on-off:before {
  content: "\e982";
}
.icon-other-id:before {
  content: "\e983";
}
.icon-out-amenity:before {
  content: "\e984";
}
.icon-out-clipboard:before {
  content: "\e985";
}
.icon-out-package:before {
  content: "\e987";
}
.icon-out:before {
  content: "\e988";
}
.icon-package:before {
  content: "\e989";
}
.icon-parking:before {
  content: "\e98a";
}
.icon-party:before {
  content: "\e98b";
}
.icon-passport:before {
  content: "\e98c";
}
.icon-person:before {
  content: "\e98e";
}
.icon-phone:before {
  content: "\e98f";
}
.icon-pin:before {
  content: "\e990";
}
.icon-police:before {
  content: "\e991";
}
.icon-print:before {
  content: "\e992";
}
.icon-priority:before {
  content: "\e993";
}
.icon-profile1:before {
  content: "\e995";
}
.icon-qr-code:before {
  content: "\e996";
}
.icon-question-mark-circle:before {
  content: "\e997";
}
.icon-question-mark:before {
  content: "\e998";
}
.icon-refresh:before {
  content: "\e999";
}
.icon-reload:before {
  content: "\e99a";
}
.icon-report:before {
  content: "\e99c";
}
.icon-request:before {
  content: "\e99d";
}
.icon-reservation:before {
  content: "\e99e";
}
.icon-save:before {
  content: "\e99f";
}
.icon-scan:before {
  content: "\e9a0";
}
.icon-search-authorized:before {
  content: "\e9a1";
}
.icon-search-person:before {
  content: "\e9a2";
}
.icon-search:before {
  content: "\e9a3";
}
.icon-send:before {
  content: "\e9a4";
}
.icon-settings:before {
  content: "\e9a5";
}
.icon-signature:before {
  content: "\e9a6";
}
.icon-sitemap:before {
  content: "\e9a7";
}
.icon-sliders:before {
  content: "\e9a8";
}
.icon-swap:before {
  content: "\e9a9";
}
.icon-taxi:before {
  content: "\e9aa";
}
.icon-template:before {
  content: "\e9ab";
}
.icon-time:before {
  content: "\e9ad";
}
.icon-trash:before {
  content: "\e9ae";
}
.icon-uber:before {
  content: "\e9af";
}
.icon-ubereats:before {
  content: "\e9b0";
}
.icon-uncheck-all:before {
  content: "\e9b1";
}
.icon-undo:before {
  content: "\e9b2";
}
.icon-update-camera:before {
  content: "\e9b3";
}
.icon-update-package:before {
  content: "\e9b4";
}
.icon-update-work-order:before {
  content: "\e9b5";
}
.icon-upload:before {
  content: "\e9b6";
}
.icon-vehicle:before {
  content: "\e9b7";
}
.icon-video:before {
  content: "\e9b8";
}
.icon-visibility-off:before {
  content: "\e9b9";
}
.icon-visibility-on:before {
  content: "\e9ba";
}
.icon-volume:before {
  content: "\e9bb";
}
.icon-week:before {
  content: "\e9bc";
}
.icon-work-order:before {
  content: "\e9be";
}
.icon-filter:before {
  content: "\ea5b";
}
